.wrapper {
  position: relative;
  width: 100%;
}
.password {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  width: 85%;
  height: 2.8rem;
  border: 1px solid hsla(0, 0%, 69%, 0.74);
  background-color: var(--bg-accent);
  color: hsl(0, 0%, 4%);
  margin: 0.3rem auto;
  border-radius: 5px;
  padding: 10px;
}
.password:focus,
.password:hover {
  background-color: hsl(0, 0%, 99%);
}
.password::placeholder {
  color: hsl(240, 1%, 40%);
}

.eye {
  position: absolute;
  right: 10%;
  top: 38%;
  z-index: 5;
  cursor: pointer;
  background-color: transparent;
  border:none;
}
.eye svg{
  fill:hsl(0, 0%, 4%);
}
.invalid {
  border-bottom: 1.5px solid hsl(31, 96%, 45%);
}
