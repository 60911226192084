* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
.container {
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  min-height: 100vh;
  width: 100%;
  opacity: 1;
  padding-top: 2rem;
  display: grid;
  place-items: center;
  position: relative;
}

.container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: var(--login-opacity);
  background-color: var(--login-backup);
  background-image: linear-gradient(
      135deg,
      var(--login-bg) 25%,
      transparent 25%
    ),
    linear-gradient(225deg, var(--login-bg) 25%, transparent 25%),
    linear-gradient(45deg, var(--login-bg) 25%, transparent 25%),
    linear-gradient(315deg, var(--login-bg) 25%, var(--login-backup) 25%);
  background-position: 4px 0, 4px 0, 0 0, 0 0;
  background-size: 4px 4px;
  background-repeat: repeat;
  z-index: 1;
}
.logo {
  position: fixed;
  top: 1rem;
  left: 1rem;
  height: 3rem;
  z-index: 10;
  display: flex;
  align-items: center;
  gap: 0.4em;
}
.logo img {
  height: 3rem;
}
.logo p {
  font-size: 1.55rem;
  font-weight: 600;
  font-style: italic;
  letter-spacing: 1.1px;
  color: var(--text);
}

.input__form {
  width: max(470px, 17vw);
  background: var(--login-backup);
  box-shadow: var(--box-shadow);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 3rem 1.5rem 1.5rem 1.5rem;
  border-radius: 5px;
  position: relative;
  z-index: 10;
  margin-bottom: 1rem;
}

.heading {
  color: var(--text);
  font-size: 1.8rem;
  font-weight: 600;
  align-self: start;
  margin-left: 7%;
  margin-bottom: 1.2rem;
}

.input {
  display: block;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 1px;
  width: 85%;
  height: 2.8rem;
  border: 1px solid hsla(0, 0%, 69%, 0.74);
  background-color: var(--bg-accent);
  color: hsl(0, 0%, 4%);
  margin: 13px 0 5px 0;
  border-radius: 5px;
  padding: 10px;
}

.input:focus,
.input:hover {
  background-color: hsl(0, 0%, 99%);
}
.input::placeholder {
  color: hsl(240, 1%, 40%);
}
.loader {
  height: 3rem;
  width: 3rem;
  text-align: center;
}
.btn__primary {
  color: hsl(0, 0%, 94%);
  background-color: hsl(359, 78%, 46%);
  border: none;
  height: 3.5rem;
  width: 85%;
  font-size: 1.1rem;
  letter-spacing: 1.3px;
  margin-top: 0.85rem;
  margin-bottom: 0.5rem;
  border-radius: 5px;
  cursor: pointer;
  position: relative;
}

.btn__primary:hover,
.btn__primary:focus {
  background-color: hsl(359, 94%, 38%);
}

.btn__primary:disabled,
.btn__primary:disabled:hover,
.btn__primary:disabled:focus {
  background-color: hsl(359, 94%, 20%);
  color: hsl(0, 0%, 94%);
  border-color: hsl(0, 0%, 80%);
  cursor: not-allowed;
  font-weight: 600;
}

.btn__secondary {
  color: var(--text-tertiary);
  border: 1px solid hsl(0, 0%, 44%);

  background-color: transparent;
  height: 3.5rem;
  width: 85%;
  font-size: 1.1rem;
  letter-spacing: 1.3px;
  margin-top: 0.5rem;
  margin-bottom: 2rem;
  border-radius: 5px;
  cursor: pointer;
  transition: all 300ms ease;
}

.btn__secondary:hover,
.btn__secondary:focus {
  color: hsl(359, 94%, 46%);
  border: 1px solid hsl(359, 94%, 46%);
}

.signIn__link {
  font-weight: 400;
  font-size: 1.1rem;
  letter-spacing: 1px;
  color: var(--text-accent);
  padding-top: 1.7rem;
  border-top: 3px solid hsl(246, 8%, 22%);
}

.signIn__link > a {
  color: hsl(359, 94%, 46%);
  font-weight: 700;
  cursor: pointer;
}

a:hover,
a:focus {
  color: hsl(359, 95%, 38%);
}
.loader::after {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  border: 2px solid transparent;
  border-top-color: hsl(240, 1%, 60%);
  border-radius: 50%;
  animation: loading-spinner 1s ease infinite;
}

@keyframes loading-spinner {
  from {
    transform: rotate(0turn);
  }
  to {
    transform: rotate(1turn);
  }
}

.invalid {
  border-bottom: 1.5px solid hsl(31, 96%, 45%);
}

.error {
  color: hsl(31, 96%, 45%);
  text-align: left;
  line-height: 1.2;
}
.error ul {
  list-style: none;
}

.alert {
  color: hsl(359, 95%, 38%);
  background: hsla(359, 95%, 88%, 0.8);
  border-left: 3px solid hsl(359, 95%, 38%);
  display: flex;
  align-items: flex-start;
  width: 85%;
  padding: 10px 5px;
  margin-bottom: 0.5rem;
  border-radius: 3px;
}
.alert svg,
.alert h4 {
  margin-left: 8px;
}
.alert svg {
  flex-basis: 22px;
  flex-shrink: 0;
}
.alert h4 {
  font-size: 0.9rem;
  line-height: 1.3;
}

@media screen and (max-width: 768px) {
  .input__form {
    width: max(450px, 55vw);
  }
  .input {
    min-height: 2rem;
    margin: 0.3rem 0;
  }
}

@media screen and (max-width: 480px) {
  .input__form {
    width: 98vw;
  }
  .btn__primary,
  .btn__secondary {
    min-height: 3.5rem;
  }
}
