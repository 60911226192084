.section {
  display: inline-block;
  margin: 0 auto;
}
.section div {
  position: absolute;
  top: 50%;
  left: 45%;
  height: 6rem;
  width: 6rem;
  border: 2px solid hhsl(200, 100%, 20%);
  border-color: hhsl(200, 100%, 20%) transparent transparent transparent;
  border-radius: 50%;
  animation: spinner 1.1s cubic-bezier(0.5, 0, 0.5, 1) infinite;
}

.section :nth-child(1) {
  animation-delay: -0.45s;
}
.section :nth-child(2) {
  animation-delay: -0.3s;
}
.section :nth-child(3) {
  animation-delay: -0.15s;
}
@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media screen and (max-width: 768px) {
.section div {
  left: 40%;
}
}