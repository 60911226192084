.notification-container {
  font-size: 1rem;
  box-sizing: border-box;
  position: fixed;
  z-index: 50;
  top: 12px;
  right: 12px;
  animation: toast 0.7s;
}

.notification {
  background: #fff;
  transition: 0.3s ease;
  position: relative;
  pointer-events: auto;
  overflow: hidden;
  margin: 0 0 6px;
  padding: 20px;
  margin-bottom: 15px;
  width: 300px;
  max-height: 100px;
  border-radius: 3px;
  box-shadow: 0 0 10px #999;
  color: #000;
  opacity: 0.95;
}

.notification:hover {
  box-shadow: 0 0 12px #fff;
  opacity: 1;
  cursor: pointer;
}

.notification-title {
  font-weight: 700;
  font-size: 16px;
  text-align: left;
  margin-top: 0;
  margin-bottom: 6px;
  width: 300px;
  height: 18px;
}

.notification-message {
  margin: 0;
  text-align: left;
  height: 18px;
  margin-left: -1px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.notification-image {
  float: left;
  margin-right: 15px;
  font-size: 26px;
}

.toast {
  height: 50px;
  width: 22rem;
  color: #fff;
  padding: 10px 15px;
}

.close-button {
  position: absolute;
  top: 4px;
  right: 8px;
  font-size: 24px;
}

@keyframes toast {
  from {
    transform: translateX(100%);
  }
  to {
    translate: translateX(0);
  }
}
