body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --bg: hsl(0, 0%, 98%);
  --text: hsl(0, 0%, 0%);
  --text-accent: hsl(0, 0%, 30%);

  --text-primary: hsl(0, 0%, 7%);
  --text-secondary: hsl(200, 93%, 8%);
  --text-tertiary: hsl(0, 0%, 15%);

  --text-error: hsl(355, 61%, 32%);

  --bg-primary: hsl(200, 93%, 33%);
  --bg-secondary: hsl(0, 0%, 90%);
  --bg-accent: hsl(0, 0%, 100%);

  --btn-primary-border: 1px solid hsl(200, 100%, 95%);

  --box-shadow: 0 0 8px hsl(0, 0%, 70%);

  --login-opacity:0.2;
  --login-bg:hsl(200, 57%, 72%);
  --login-backup: hsl(255, 1%, 98%);
}

[data-theme="dark"] {
  --bg: hsl(0, 0%, 5%);
  --text: hsl(192, 19%, 95%);
  --text-accent: hsl(206, 7%, 56%);

  --text-primary: hsl(0, 0%, 97%);
  --text-secondary: hsl(200, 93%, 88%);
  --text-tertiary: hsl(0, 0%, 85%);

  --bg-primary: hsl(200, 98%, 43%);
  --bg-secondary: hsla(0, 0%, 30%, 0.7);
  --bg-accent: hsl(220, 12%, 8%);

  --text-error: hsl(355, 78%, 40%);

  --btn-primary-border: 1px solid hsl(200, 93%, 43%);

  --box-shadow: 0 0 4px hsl(0, 0%, 39%);

  --login-opacity:0.9;
  --login-bg:hsl(200, 65%, 5%) ;
   --login-backup: hsl(240, 53%, 3%);
}

@media (prefers-color-scheme: light) {
  body {
    background-color: var(--bg);
    color: var(--text);
  }
}

@media (prefers-color-scheme: dark) {
  * {
    scrollbar-color: var(--text-accent) var(--bg-accent);
  }
  body {
    background-color: var(--bg);
    color: var(--text);
  }
}
